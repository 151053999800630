















import Vue, { PropType } from 'vue';
import { Socket } from '@/modules/elec/Socket';
import { CircadianClock } from '@/modules/time/CircadianClock';
import { LegibleDuration } from '@/modules/time/LegibleDuration';
import { SocketType } from '@/types/elec/SocketType';

export default Vue.extend({
  name: 'Duration',

  computed: {
    result() {
      return LegibleDuration(this.minutes);
    },
  },

  props: {
    minutes: {
      type: Number as PropType<number>,
      required: true,
    },
  },
});
