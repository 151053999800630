












































import Vue, { PropType } from 'vue';
import TimeAgo from 'javascript-time-ago';

// eslint-disable-next-line import/extensions
import en from 'javascript-time-ago/locale/en';
import { CurrencyCode } from '@/types/cash/CurrencyCode';
import { Denomination } from '@/types/cash/Denomination';
import { CurrencyComparison } from '@/types/cash/CurrencyComparison';

TimeAgo.addDefaultLocale(en);

interface DenominationDisplay {
  amount?: number;
  rate?: string;
  referenced?: string;
  info?: string;
  code: CurrencyCode;
  colloquial: string;
}

export default Vue.extend({
  name: 'DenominationList',

  computed: {
    all(): DenominationDisplay[] {
      if (this.list) {
        return this.list.map((denomination) => ({
          ...denomination,
        }));
      }

      if (!this.rates) {
        throw new Error('DenominationList requires props');
      }

      const timeAgo = new TimeAgo('en-US');

      return this.rates.map((comparison) => ({
        ...comparison.local,
        amount: comparison.howManyLocal,
        rate: comparison.rate,
        referenced: comparison.wasReliableAt ? timeAgo.format(comparison.wasReliableAt) : '',
      }));
    },
  },

  props: {
    list: {
      type: Array as PropType<(Denomination)[]>,
      required: false,
    },
    rates: {
      type: Array as PropType<CurrencyComparison[]>,
      required: false,
    },
  },
});
