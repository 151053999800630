import { CountryCode } from '@/types/CountryCode';
import { DataRecord } from '@/types/DataRecord';
import { DataRecordTools } from '@/modules/DataRecordTools';
import { WaterSafety } from '@/types/aqua/WaterSafety';
import data from '@/data/aqua/water.json';
import { Country } from 'countries-and-timezones';

const waterInfo = data as {
  [code in CountryCode]?: {
    waterIsSafe: boolean | null;
    footnote?: string;
  } & DataRecord;
};

class FaultyWaterSafetyError extends Error {
  constructor(message: string, public readonly country: CountryCode) {
    super(`${message} for country '${country}'`);
    this.name = 'FaultyWaterSafetyError';
  }
}

export class Water {
  public readonly info: Promise<WaterSafety>;

  constructor(private readonly destination: CountryCode) {
    const foo = waterInfo[destination];
    if (!foo || foo.waterIsSafe === null) {
      this.info = Promise.reject(new FaultyWaterSafetyError('No water safety found', destination));
    } else {
      this.info = Promise.resolve({
        safe: foo.waterIsSafe,
        footnote: foo.footnote,
        ...DataRecordTools.parse(foo),
      });
    }
  }

  public async get(origin?: CountryCode): Promise<WaterSafety> {
    if (!origin) {
      return this.info;
    }

    const safeAtHome = await new Water(origin)
      .info
      .then(({ safe }) => safe)
      .catch(() => undefined);

    return {
      ...await this.info,
      safeAtHome,
    };
  }
}
