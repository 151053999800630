







































































import Vue, { PropType } from 'vue';
import {
  Currency, CurrencyCompatibilityReport,
} from '@/modules/cash/Currency';
import { CountryCode, CountryCodes } from '@/types/CountryCode';
import CountryName from '@/components/CountryName.vue';
import { Compatibility } from '@/types/Compatibility';
import DenominationList from '@/components/cash/DenominationList.vue';
import { convertToText } from 'number-to-text';
import { Denomination } from '@/types/cash/Denomination';

require('number-to-text/converters/en-us'); // load converter

export default Vue.extend({
  name: 'CurrencyReport',

  props: {
    report: {
      type: Object as PropType<CurrencyCompatibilityReport>,
      required: false,
    },
  },

  computed: {
    amount(): string {
      if (!this.report || !this.report.destinationExclusiveDenominations.length) {
        return '';
      }

      const capitalizeFirst = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

      return capitalizeFirst(convertToText(this.report.destinationExclusiveDenominations[0].howManyForeign).toLowerCase());
    },
  },

  components: {
    CountryName,
    DenominationList,
  },

  data() {
    return {
      Compatibility,
    };
  },
});
