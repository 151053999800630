





































import Vue, { PropType } from 'vue';
import { Socket } from '@/modules/elec/Socket';
import {
  CircadianClock,
} from '@/modules/time/CircadianClock';
import { LegibleDuration } from '@/modules/time/LegibleDuration';
import { JetlagStatusReport } from '@/modules/time/Jetlag';
import { DirectionalJetlag } from '@/types/time/DirectionalJetlag';
import { InclinationTowardsLight } from '@/types/time/InclinationTowardsLight';
import { BedtimeShift } from '@/types/time/BedtimeShift';
import { LightInfo } from '@/types/time/LightInfo';
import { SocketType } from '@/types/elec/SocketType';

export default Vue.extend({
  name: 'JetlagInfo',

  data() {
    return {
      BedtimeShift,
      DirectionalJetlag,
      JetlagStatusReport,
    };
  },

  props: {
    bedtimeShift: {
      type: String as PropType<BedtimeShift>,
      required: false,
    },
    jetlagEffectOfDirection: {
      type: String as PropType<DirectionalJetlag>,
      required: false,
    },
    lightInfo: {
      type: Object as PropType<LightInfo>,
      required: false,
    },
  },
  computed: {
    hasColloquialLightSeekInfo() {
      return typeof (this?.lightInfo as LightInfo | undefined)?.seek?.outOfOrdinaryTime?.timeOfDay !== 'undefined';
    },
    hasColloquialLightAvoidInfo() {
      return typeof (this?.lightInfo as LightInfo | undefined)?.avoid?.outOfOrdinaryTime?.timeOfDay !== 'undefined';
    },
  },
});
