






















































import Vue, { PropType } from 'vue';
import { CountryCode } from '@/types/CountryCode';
import { NameOfCountry } from '@/modules/NameOfCountry';
import CountryCard from '@/components/CountryCard.vue';
import { CardImage } from '@/types/CardImage';
import { CardPriority } from '@/types/CardPriority';
import { CardType } from '@/types/CardType';
import { Jetlag, JetlagStatusReport, TimeDifferenceReport } from '@/modules/time/Jetlag';
import TimeReport from '@/components/time/TimeReport.vue';
import { convertToText } from 'number-to-text';
import { LegibleDuration } from '@/modules/time/LegibleDuration';
import { Clock } from '@/modules/time/Clock';
import { CardMixin } from '@/mixins/CardMixin';
import mixins from 'vue-typed-mixins';
import { Timezone } from 'countries-and-timezones';
import Duration from '@/components/time/Duration.vue';
import { Platform } from '@/modules/Platform';

interface Data {
  report: TimeDifferenceReport | undefined;
  timezone: Timezone[] | undefined;
}

export default mixins(CardMixin).extend({
  name: 'TimeCard',

  components: {
    Duration,
    TimeReport,
  },

  watch: {
    originTimezone: {
      handler() {
        this.loadReport();
      },
    },
    pending: {
      handler() {
        this.loadReport();
      },
    },
  },

  data(): Data {
    return {
      report: undefined,
      timezone: undefined,
    };
  },

  computed: {

    cardType() {
      return CardType.TIME;
    },

    currently(): string {
      return this.report?.dstDiscrepancyExists ? ' currently ' : ' ';
    },

    subtitle(): string {
      if (this.loading) {
        return '';
      }

      switch (this.report?.jetlagStatus as (JetlagStatusReport | undefined)) {
      case JetlagStatusReport.EQUIVALENT_TIMEZONES:
        return `${NameOfCountry.get(this.report?.destination)} is${this.currently}in your timezone`;

      case JetlagStatusReport.NO_JETLAG_EXPECTED:
        return 'You will observe a time difference';

      case JetlagStatusReport.COMPLETE:
        return 'You will likely experience jet lag';

      case JetlagStatusReport.PARTIAL_DEPENDING_ON_ZONE:
      case JetlagStatusReport.INCOMPLETE_DEPENDING_ON_ZONE:
        return this.report?.jetlagInAllZones ? 'You will likely experience jet lag' : 'You might experience jet lag';

      default:
        return 'No info available';
      }
    },

    cardImages(): CardImage[] {
      if (this.loading) {
        return [{
          description: 'Loading time difference data',
          icon: 'mdi-clock-outline',
        }];
      }

      if (!this.report && this.timezone && this.timezone.length) {
        const timezones = this.timezone.map(({ name }) => name);

        return [{
          description: timezones.length > 1
            ? `${NameOfCountry.get(this.destination)} spans ${timezones.length} distinct time zones`
            : `${NameOfCountry.get(this.destination)} is in the ${timezones[0]} time zone`,
          icon: 'mdi-clock-outline',
        }];
      }

      if (this.error) {
        return [{
          description: this.error,
          icon: 'mdi-clock-alert-outline',
        }];
      }

      if (!this.report) {
        return [{
          description: this.subtitle,
          icon: 'mdi-clock-alert-outline',
        }];
      }

      return this.report.destinationTimezonesOrdered.map(({ name, minutesClockTurn: mins }, i, arr) => {
        let description: string;

        const minutesClockTurn = mins || 0;
        const suffix = arr.length > 1 ? ` (${i + 1}/${arr.length})` : '';

        if (minutesClockTurn > 0) {
          description = `You'd turn your clocks ${LegibleDuration(minutesClockTurn)} forward in the ${name} timezone`;
        } else if (minutesClockTurn < 0) {
          description = `You'd turn your clocks ${LegibleDuration(minutesClockTurn)} backward in the ${name} timezone`;
        } else {
          return {
            icon: 'mdi-clock-check-outline',
            description: `The ${name} timezone is ${this.currently} the same as yours${suffix}`,
          };
        }

        let hour = Math.ceil(Clock.mod(minutesClockTurn / 60, 12));
        if (hour === 0) {
          hour = 12;
        }

        return {
          icon: `mdi-clock-time-${convertToText(hour).toLowerCase()}-outline`,
          description: description + suffix,
        };
      });
    },

    cardBackground(): CardPriority {
      if (this.loading || this.error || !this.report || !this.origin) {
        return CardPriority.OK;
      }

      switch (this.report.jetlagStatus) {
      case JetlagStatusReport.EQUIVALENT_TIMEZONES:
        return CardPriority.OK;

      case JetlagStatusReport.NO_JETLAG_EXPECTED:
        return CardPriority.WARNING;

      default:
        return CardPriority.ISSUE;
      }
    },
  },

  methods: {

    changeHomeCountry() {
      this.$store.commit('toggleHomeCountryDialog', true);
    },

    async loadReport(): Promise<void> {
      if (this.pending) {
        return; // Parent might still be setting the timezone, which is an async operation
      }

      this.isLoadingData = true;
      this.error = undefined;
      this.report = undefined;
      this.timezone = undefined;

      try {
        const reporter = new Jetlag(this.destination);
        this.timezone = await reporter.timezoneInfo;

        if (!this.originTimezone || !this.origin) {
          throw new Error("Can't calculate time difference without an origin timezone");
        }

        // Origin timezone should have been validated at set-time
        this.report = await reporter.get(this.origin, this.originTimezone).catch(() => undefined);
      } catch (e) {
        this.error = this.error || `No timezone data found for ${NameOfCountry.get(this.destination) || this.destination.toUpperCase()}`;
      }
      this.isLoadingData = false;
    },
  },
});
