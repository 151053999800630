import Vue, { PropType } from 'vue';
import { CountryCode } from '@/types/CountryCode';
import { CardPriority } from '@/types/CardPriority';
import { CardType } from '@/types/CardType';
import CountryCard from '@/components/CountryCard.vue';
import CountryName from '@/components/CountryName.vue';

interface Data {
  isLoadingData: boolean;
  error?: string;
}

export const CardMixin = Vue.extend({

  data(): Data {
    return {
      isLoadingData: true,
      error: undefined,
    };
  },

  components: {
    CountryCard,
    CountryName,
  },

  props: {
    destination: {
      type: String as PropType<CountryCode>,
      required: true,
    },
    pending: {
      type: Boolean as PropType<boolean>,
      required: false,
    },
  },

  computed: {

    cardType(): CardType | undefined {
      return undefined;
    },
    loading(): boolean {
      return this.isLoadingData || this.pending;
    },

    origin(): CountryCode {
      return this.$store.state.home;
    },

    originTimezone(): string | undefined {
      return this.$store.state.homeTimezone;
    },
  },

  created() {
    this.loadReport();
  },

  methods: {
    loadReport() {
      throw new Error('Mixin requires a report fetcher');
    },
  },

  watch: {
    destination: {
      handler() {
        this.loadReport();
      },
    },
    origin: {
      handler() {
        this.loadReport();
      },
    },

    loading: {
      handler(newVal: boolean | undefined) {
        if (newVal !== false) {
          return;
        }

        if (typeof this.cardType === 'undefined') {
          throw new Error('Card type not defined!');
        }

        this.$store.commit('reportCardInitialized', this.cardType);
      },
      immediate: true,
    },

    cardBackground: {
      immediate: true,
      handler(color: CardPriority) {
        if (typeof this.cardType === 'undefined') {
          throw new Error('Card type not defined!');
        }

        this.$store.commit('reportCardPriority', {
          card: this.cardType,
          color,
        });
      },
    },
  },
});
