











































































































































import { PropType } from 'vue';
import { CountryCode, CountryCodes } from '@/types/CountryCode';
import CountryName from '@/components/CountryName.vue';
import ElecCard from '@/components/elec/ElecCard.vue';
import CashCard from '@/components/cash/CashCard.vue';
import { NameOfCountry } from '@/modules/NameOfCountry';
import { FavouriteCountries } from '@/modules/FavouriteCountries';
import { HomeCountry, StoredHomeCountry } from '@/modules/HomeCountry';
import { LinkPropertyHref, MetaInfo } from 'vue-meta';
import { CountryRoute } from '@/router/CountryRoute';
import { CardType, DefaultCardOrder } from '@/types/CardType';
import TimeCard from '@/components/time/TimeCard.vue';
import mixins from 'vue-typed-mixins';
import { ViewMixin } from '@/mixins/ViewMixin';
import TipsCard from '@/components/tips/TipsCard.vue';
import { RawLocation, Route } from 'vue-router';
import { CountryCodeAvailability } from '@/store';
import AquaCard from '@/components/aqua/AquaCard.vue';

interface Data {
  loading: boolean;
  snackbar: boolean;
  CardType: typeof CardType;
}

export default mixins(ViewMixin).extend({
  name: 'Destination',

  data(): Data {
    return {
      loading: true,
      snackbar: false,
      CardType,
    };
  },

  computed: {

    detectedCountry(): StoredHomeCountry | undefined {
      return this.$store.state.detectedHome;
    },

    blockOriginDetection(): boolean {
      return !!(process.env.NODE_ENV !== 'production' && this.$route.query.doNotDetect);
    },

    storedOrigin(): CountryCode | undefined {
      return this.$store.state.home;
    },

    allSet(): boolean {
      return Object.values(this.$store.state.cardInitialized).every((value) => value);
    },

    storedFavs(): CountryCode[] {
      return this.$store.state.favourites;
    },

    country(): CountryCode {
      return this.$route.params.destination as CountryCode;
    },

    isFav(): boolean {
      if (!this.country) {
        return false;
      }

      return this.storedFavs.includes(this.country);
    },
    canAddFav(): boolean {
      return this.storedFavs.length < FavouriteCountries.MAXIMUM;
    },

    cardOrder(): CardType[] {
      const priorities = this.$store.state.cardPriorities;

      const sorted = DefaultCardOrder
        .map((card) => ({
          card,
          priority: priorities[card],
        }))
        .sort((a, b) => b.priority - a.priority); // Leverage enum order

      return sorted.map(({ card }) => card);
    },
  },

  props: {
    originOverride: {
      type: String as PropType<CountryCode>,
      required: false,
    },
  },

  watch: {

    country() {
      this.populateUrl();
    },

    detectedCountry(newValue?: StoredHomeCountry) {
      this.originSyncUrlWithState();
    },

    // Function: to reflect an origin change in the address bar
    storedOrigin: {
      handler(newOne: CountryCode, oldOne: CountryCode | undefined) {
        if (!this.country) {
          return;
        }
        this.populateUrl();
      },
      immediate: true,
    },

    // Function: react to having an origin country in the url (escalate the information if it's new)
    // Function: react to not having an origin country in the url (retrieve from storage / autodetect, or fallback on originless; redirect)
    $route: {
      async handler({ params, fullPath }: Route) {
        // We need to re-set the initial data here, since for our purposes this might be a whole new component
        this.loading = true;
        this.snackbar = false;

        await this.originSyncUrlWithState();

        this.loading = false;
      },
      immediate: true,
    },
  },

  metaInfo(): MetaInfo {
    let title = 'Traveling';

    const { country } = this;

    if (country) {
      const suffix = this.storedOrigin ? ` from ${NameOfCountry.get(this.storedOrigin)}` : '';
      title = `Visiting ${NameOfCountry.get(country)}${suffix}`;
    }

    const link: LinkPropertyHref[] = [];

    const meta = [{
      vmid: 'description',
      name: 'description',
      // eslint-disable-next-line max-len
      content: `Understand the local tipping culture, currency information, electrical compatibility, water safety, and the time difference in ${NameOfCountry.get(this.country)}`,
    }];

    if (country) {
      link.push(
        {
          rel: 'canonical',
          href: CountryRoute.from(country).getCanonicalURL().toString(),
        },
        {
          rel: 'alternate',
          href: CountryRoute.from(country).getCanonicalURL().toString(),
          hreflang: 'en',
        },
        ...CountryCodes.map((code: CountryCode) => ({
          rel: 'alternate',
          href: CountryRoute.from(country).getCanonicalURL(code).toString(),
          hreflang: `en-${code.toUpperCase()}`,
        })),
      );
    }

    return {
      title,
      link,
      meta,
    };
  },

  methods: {

    setHomeCountry({ country: code, timezone }: StoredHomeCountry) {
      this.$store.commit('setHomeCountry', {
        code,
        timezone,
        source: CountryCodeAvailability.ESTABLISHED_PREVIOUSLY,
      });
    },

    /**
     * Makes sure that the origin in the URL is the same as the origin in the application store.
     * Leverages autodetected country/timezone where appropriate.
     */
    async originSyncUrlWithState() {
      const { originOverride } = this;

      if (originOverride) {
        // We have origin info in the URL
        const previouslyStoredOrigin = HomeCountry.getStoredCountry();
        const originChanged = previouslyStoredOrigin?.country !== originOverride;

        let timezone: string | undefined = previouslyStoredOrigin?.timezone;
        if (originChanged || !timezone) {
          // Either A) The country of origin changed, so we need to unset the timezone, and hopefully deduce the new one
          // or B) The user didn't have a timezone set, and we'll hopefully deduce one for them
          timezone = await HomeCountry.attemptUserTimezoneChoice(originOverride)
            .then((result) => result?.name)
            .catch(() => undefined);

          this.$store.commit('setHomeCountry', {
            code: originOverride,
            source: originChanged ? CountryCodeAvailability.DEDUCED_FROM_URL : CountryCodeAvailability.ESTABLISHED_PREVIOUSLY,
            timezone,
          });
        }
      } else {
        // There's no origin info in the URL. Let's try to get it from HomeCountry (either stored or autodetected value)

        const storedOrigin: StoredHomeCountry | undefined = await HomeCountry.getStoredCountry();

        if (storedOrigin) {
          this.$store.commit('setHomeCountry', {
            code: storedOrigin.country,
            timezone: storedOrigin.timezone || await HomeCountry.attemptUserTimezoneChoice(originOverride)
              .then((result) => result?.name)
              .catch(() => undefined),
            source: CountryCodeAvailability.ESTABLISHED_PREVIOUSLY,
          }); // Store change will trigger an update on the storage and the component
        } else if (!this.blockOriginDetection && this.detectedCountry) {
          HomeCountry.setCountry(this.detectedCountry);
          this.$store.commit('setHomeCountry', {
            code: this.detectedCountry.country,
            timezone: this.detectedCountry.timezone,
            source: CountryCodeAvailability.DEDUCED_FROM_IP,
          }); // Store change will trigger an update on the storage and the component
        }
      }
    },

    populateUrl() {
      const newOne = this.storedOrigin;

      if (!newOne) {
        return;
      }

      const location: RawLocation = CountryRoute.createRouterLink(this.country, newOne);

      if (typeof this.originOverride === 'undefined') {
        // No origin in url, but it's in store! Likely: Country was just auto-detected
        this.$router.replace(location);
      } else if (this.originOverride && this.originOverride !== newOne) {
        // Origin in url, but it's different from the one in store! Likely: User just changed their home country
        this.$router.push(location);
      }
    },
    toggleFav() {
      if (!this.country) {
        return;
      }

      if (this.isFav) {
        this.$store.commit('removeFavourite', this.country);
        this.snackbar = false;
      } else if (this.canAddFav) {
        this.$store.commit('addFavourite', this.country);
        this.snackbar = true;
      } else {
        this.snackbar = true;
      }
    },
  },

  components: {
    TimeCard,
    CountryName,
    ElecCard,
    CashCard,
    TipsCard,
    AquaCard,
  },
});
