import currenciesData from '@/data/cash/currencies.json';
import denominationsData from '@/data/cash/denominations.json';
import { CountryCode } from '@/types/CountryCode';
import { Compatibility } from '@/types/Compatibility';
import { DataValidity, ExchangeRatesManager } from '@/modules/cash/ExchangeRatesManager';
import { CurrencyCode } from '@/types/cash/CurrencyCode';
import { DataRecord } from '@/types/DataRecord';
import { Denomination } from '@/types/cash/Denomination';
import { DenominationWithExchangeRate } from '@/types/cash/DenominationWithExchangeRate';
import { CurrencyComparison } from '@/types/cash/CurrencyComparison';

interface RawCurrencyData extends DataRecord {
    currencies: {
      code: CurrencyCode;
      info: string;
    }[];
}

export type CurrencyDatabase = {
  [code in CountryCode]?: RawCurrencyData;
}

const currencies = currenciesData as CurrencyDatabase;

interface RawDenomination extends DataRecord {
    symbols: string[];
    currency: string;
}

export type DenominationDatabase = {
    [key in CurrencyCode]?: RawDenomination;
};

const denominations = denominationsData as DenominationDatabase;

class FaultyCurrencyError extends Error {
  constructor(message: string, public readonly country: CountryCode) {
    super(`${message} for country '${country}'`);
    this.name = 'FaultyCurrencyError';
  }
}

export interface CurrencyCompatibilityReport {
    originCompatibilityWithDestination: Compatibility;

    commonDenominations: Denomination[];

    originDenominations: Denomination[];
    destinationDenominations: Denomination[];

    destinationExclusiveDenominations: DenominationWithExchangeRate[];

    destination: CountryCode;
}

export class Currency {
    private currencyInfo: Promise<Denomination[]>;

    private getDenominations(currencyData: RawCurrencyData): Denomination[] {
      return currencyData.currencies.map(({ code, info }) => {
        const denominationData = this.denominationsDb[code];

        if (!denominationData?.currency) {
          throw new Error(`Missing denomination data for ${code}`);
        }

        return {
          code,
          info,
          symbol: denominationData?.symbols || [],
          colloquial: denominationData?.currency || '',
        };
      });
    }

    constructor(
      public readonly country: CountryCode,
      private readonly db: CurrencyDatabase = currencies,
      private readonly denominationsDb: DenominationDatabase = denominations,
    ) {
      this.currencyInfo = new Promise<RawCurrencyData | undefined>(
        (resolve) => resolve(db[country]),
      )

        .then((currencyData) => {
          if (!currencyData) {
            throw new FaultyCurrencyError('No currency data listed', country);
          }

          return this.getDenominations(currencyData);
        });
    }

    private static async exchangeOneEuroFor(denomination: Denomination): Promise<{
      rate: number;
      validity: Exclude<DataValidity, DataValidity.ABSENT>;
    }> {
      if (denomination.code === 'EUR') {
        return {
          rate: 1,
          validity: DataValidity.VALID,
        };
      }

      const result = await ExchangeRatesManager.get(denomination.code);

      if (result.validity === DataValidity.ABSENT) {
        throw new Error(`Value of ${denomination.code} unobtainable from exchange rates API`);
      }

      return result;
    }

    static meaningfulMinimumEuroValue = 0.5;

    /**
     * Public for easier testing
     */
    static async getExchangeRate(
      locals: Denomination[],
      foreign: Denomination,
    ): Promise<DenominationWithExchangeRate> {
      const roundToSignificants = (n: number, digits = 2) => Number(n.toPrecision(digits));

      let n = 0;

      // Figure out what's a comfortable value for n
      const rates = await Promise.all(locals.map(async (local) => {
        const localRate = await Currency.exchangeOneEuroFor(local);
        const foreignRate = await Currency.exchangeOneEuroFor(foreign);

        const foreignValue = foreignRate.rate;
        const localValue = localRate.rate;

        const actualRate = localValue / foreignValue;
        let rate = actualRate;

        const effectOfSecondSignificantDigit = roundToSignificants(rate) / roundToSignificants(rate, 1);
        if (effectOfSecondSignificantDigit > 0.9 && effectOfSecondSignificantDigit < 1.1) {
          // Here we make the following simplification:
          // "1000 NOK is about 99 EUR" becomes "10 NOK is about 1 EUR."
          // ...while preserving "1000 NOK is about 89 EUR"
          rate = roundToSignificants(rate, 1);
        }

        /**
         * Two criteria that need to pass in order for 10^n of the foreign currency to be "enough"
         * 1. Needs to be worth something meaningful
         * 2. The integer-rounded exchanged value (in local currency) needs to have enough significant digits
         *    for a desired level of accuracy. (eg. 2.1 -> 2 is okay, but 2.4 -> 2 might not)
         */
        while (
          (1 / foreignValue) * 10 ** n < Currency.meaningfulMinimumEuroValue
          || Math.round(roundToSignificants(rate * 10 ** n)) !== roundToSignificants(rate * 10 ** n)
        ) {
          n += 1;

          if (n > 100) {
            throw new Error(`Exchange rate calculation stuck in loop with rate ${localValue}/${foreignValue} = ${actualRate}`);
          }
        }
        const { code, colloquial, symbol } = local;

        let wasReliableAt: Date | undefined;

        if (localRate.validity === DataValidity.UNRELIABLE) {
          wasReliableAt = (localRate as any).referenced as Date;
        } else if (foreignRate.validity === DataValidity.UNRELIABLE) {
          wasReliableAt = (foreignRate as any).referenced as Date;
        }

        return {
          local: {
            code,
            symbol,
            colloquial,
            // Strip away "info"
          },
          actualRate,
          rate,
          wasReliableAt,
        };
      }));

      const exchangeRates: CurrencyComparison[] = rates.map(({
        local, rate, actualRate, wasReliableAt,
      }) => ({
        local,
        rate: (actualRate * 10 ** n).toFixed(2),
        howManyLocal: roundToSignificants(rate * 10 ** n),
        wasReliableAt,
      }));

      return {
        ...foreign,
        exchangeRates,
        howManyForeign: 10 ** n,
      };
    }

    async report(home: CountryCode): Promise<CurrencyCompatibilityReport> {
      const oursRaw = this.db[home];
      if (!oursRaw) {
        throw new FaultyCurrencyError('No currency data listed', home);
      }

      const ours = this.getDenominations(oursRaw);
      const theirs = await this.currencyInfo;

      const commonDenominations: Denomination[] = [];
      const ourExclusiveDenominations: Denomination[] = [];

      ours.forEach((denomination) => {
        const match = theirs.find(({ code }) => code === denomination.code);
        if (typeof match !== 'undefined') {
          commonDenominations.push(match);
        } else {
          ourExclusiveDenominations.push(denomination);
        }
      });

      const theirExclusiveDenominations: Denomination[] = theirs.filter(
        (denomination) => typeof ours.find(({ code }) => code === denomination.code) === 'undefined',
      );

      let originCompatibilityWithDestination: Compatibility;

      if (commonDenominations.length === 0) {
        originCompatibilityWithDestination = Compatibility.NONE;
      } else if (ourExclusiveDenominations.length === 0 && theirExclusiveDenominations.length === 0) {
        originCompatibilityWithDestination = Compatibility.FULL;
      } else if (ourExclusiveDenominations.length > 0 && theirExclusiveDenominations.length === 0) {
        originCompatibilityWithDestination = Compatibility.SOME_ARE_COMPATIBLE;
      } else if (theirExclusiveDenominations.length > 0 && ourExclusiveDenominations.length === 0) {
        originCompatibilityWithDestination = Compatibility.COMPATIBLE_WITH_SOME;
      } else {
        originCompatibilityWithDestination = Compatibility.LIMITED_OVERLAP;
      }

      const destinationExclusiveDenominations: DenominationWithExchangeRate[] = await Promise.all(
        theirExclusiveDenominations.map((foreign) => Currency.getExchangeRate(ourExclusiveDenominations, foreign)),
      ).catch((error) => {
        console.error(error);
        return []; // Exchange rates are non-crucial, unknown error's shouldn't cause the whole card to fail
      });

      return {
        originCompatibilityWithDestination,
        commonDenominations,
        originDenominations: ours,
        destinationDenominations: theirs,
        destination: this.country,
        destinationExclusiveDenominations,
      };
    }

    get(): Promise<Denomination[]> {
      return this.currencyInfo;
    }
}
