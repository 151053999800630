





































import { CardImage } from '@/types/CardImage';
import { CardPriority } from '@/types/CardPriority';
import { NameOfCountry } from '@/modules/NameOfCountry';
import { CardType } from '@/types/CardType';
import { CardMixin } from '@/mixins/CardMixin';
import mixins from 'vue-typed-mixins';
import { Water } from '@/modules/aqua/Water';
import Disclaimer from '@/components/Disclaimer.vue';
import { WaterSafety } from '@/types/aqua/WaterSafety';

interface Data {
  report?: WaterSafety;
}

export default mixins(CardMixin).extend({
  name: 'AquaCard',

  components: {
    Disclaimer,
  },

  data(): Data {
    return {
      report: undefined as WaterSafety | undefined,
    };
  },

  computed: {

    cardType() {
      return CardType.AQUA;
    },

    subtitle(): string {
      if (this.loading) {
        return '';
      }

      switch (this.report?.safe) {
      case true:
        return 'Tap water is safe to drink outside of rural areas';

      case false:
        return 'Tap water is unsafe for consumption';

      default:
        return 'No water safety info available';
      }
    },

    disclaimer(): string | undefined {
      if (!this.report) {
        return undefined;
      }

      return `Source: ${this.report.src.hostname} (${this.report.referenced.getFullYear()})`;
    },

    cardImages(): CardImage[] {
      if (this.loading) {
        return [{
          description: 'Loading water safety info',
          icon: 'mdi-water-outline',
        }];
      }

      if (this.error) {
        return [{
          description: this.error,
          icon: 'mdi-water-outline',
        }];
      }

      switch (this.report?.safe) {
      case true:
        return [{
          description: this.subtitle,
          icon: 'mdi-water-check-outline',
        }];

      case false:
        return [{
          description: this.subtitle,
          icon: 'mdi-water-alert-outline',
        }];

      default:
        return [{
          description: this.subtitle,
          icon: 'mdi-water-outline',
        }];
      }
    },

    cardBackground(): CardPriority {
      if (!this.origin || !this.report || this.loading || this.error) {
        return CardPriority.OK;
      }

      // If report is unsafe, and we at home are unsafe
      if (!this.report.safe && this.report.safeAtHome === false) {
        return CardPriority.WARNING; // Not safe, but not safe at home either
      }

      if (!this.report.safe) {
        return CardPriority.ISSUE; // Not safe, and home (maybe) is safe
      }

      return CardPriority.OK;
    },
  },

  methods: {
    async loadReport(): Promise<void> {
      this.isLoadingData = true;
      this.error = undefined;
      this.report = undefined;

      try {
        try {
          this.report = await new Water(this.destination).get(this.origin);
        } catch (e) {
          const country = NameOfCountry.get(this.destination) || this.destination.toUpperCase();
          this.error = `No water data found for ${country}`;
          throw e;
        }
      } catch (e) {
        this.report = undefined;
      }

      this.isLoadingData = false;
    },
  },
});
