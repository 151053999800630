























































import Vue, { PropType } from 'vue';
import { Socket } from '@/modules/elec/Socket';
import { SocketType } from '@/types/elec/SocketType';

export default Vue.extend({
  name: 'SocketName',

  props: {
    socket: {
      type: Object as PropType<SocketType>,
      required: true,
    },
    context: {
      type: Array as PropType<SocketType[]>,
      required: true,
    },
    clickable: {
      type: Boolean as PropType<boolean>,
    },
    fullNames: {
      type: Boolean as PropType<boolean>,
    },
  },

  data() {
    return {
      hasGroundingPair: typeof this.context.find(
        (socket) => Socket.areGroundingPairs(socket, this.socket),
      ) !== 'undefined',
    };
  },

  methods: {
    clicked() {
      this.$emit('click');
    },
  },
});
