
















































































import Vue, { PropType } from 'vue';
import * as onClickaway from 'vue-clickaway';

interface Data {
  dialog: boolean;
}

export default Vue.extend<Data, {closeDrawer(): void}, {}, {title: string; button: string}>({
  name: 'Disclaimer',

  data(): Data {
    return {
      dialog: false,
    } as Data;
  },

  props: {
    title: {
      type: String as PropType<string>,
      required: true,
    },
    button: {
      type: String as PropType<string>,
      required: false,
    },
  },

  created() {
    // Make back button close the dialog
    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
      if (this.dialog) {
        this.dialog = false;
        next(false);
      } else {
        next();
      }
    });

    this.$once('hook:destroyed', () => {
      // This will be called when the component is destroyed.
      unregisterRouterGuard();
    });
  },

  methods: {
    closeDrawer() {
      // NOTE: The native close behavior didn't work in DEPLOYED Android
      this.dialog = false;
    },
  },

  directives: {
    onClickaway: onClickaway.directive,
  },
});
