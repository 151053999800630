



























































































































































































import Vue, { PropType } from 'vue';
import {
  Currency, CurrencyCompatibilityReport,
} from '@/modules/cash/Currency';
import { CountryCode, CountryCodes } from '@/types/CountryCode';
import CountryName from '@/components/CountryName.vue';
import { Compatibility } from '@/types/Compatibility';
import DenominationList from '@/components/cash/DenominationList.vue';
import { convertToText } from 'number-to-text';
import { TimeDifferenceReport, JetlagStatusReport } from '@/modules/time/Jetlag';
import Duration from '@/components/time/Duration.vue';
import { DirectionalJetlag } from '@/types/time/DirectionalJetlag';
import Disclaimer from '@/components/Disclaimer.vue';
import JetlagInfo from '@/components/time/JetlagInfo.vue';
import { InclinationTowardsLight } from '@/types/time/InclinationTowardsLight';
import { BedtimeShift } from '@/types/time/BedtimeShift';
import { Denomination } from '@/types/cash/Denomination';

export default Vue.extend({
  name: 'TimeReport',

  props: {
    report: {
      type: Object as PropType<TimeDifferenceReport>,
      required: false,
    },
  },

  methods: {
    changeHomeTimezone() {
      this.$store.commit('toggleHomeCountryDialog', true);
    },
    parseTime(minutes: number): string {
      const now = new Date();
      now.setHours(0, minutes, 0, 0); // Done in user TZ (as opposed to UTC)...
      const [time] = now.toTimeString().split(' '); // ...so that string includes the user TZ
      const [h, m] = time.split(':');

      return `${h}:${m}`;
    },
  },

  computed: {
    moreInfoAvailable(): boolean {
      return this.report?.jetlagStatus === JetlagStatusReport.INCOMPLETE_DEPENDING_ON_ZONE
        || this.report?.jetlagStatus === JetlagStatusReport.PARTIAL_DEPENDING_ON_ZONE;
    },
    numberOfTimezones(): string {
      const howMany = this?.report?.destinationTimezonesOrdered?.length;

      if (typeof howMany === 'undefined') {
        return 'an unknown number';
      }

      return convertToText(howMany).toLowerCase();
    },
  },

  components: {
    Duration,
    JetlagInfo,
    CountryName,
    Disclaimer,
  },

  data() {
    return {
      DirectionalJetlag,
      JetlagStatusReport,
      InclinationTowardsLight,
      BedtimeShift,
    };
  },
});
