var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"time-report"},[(_vm.report)?_c('v-card-text',[(_vm.report.jetlagStatus !== _vm.JetlagStatusReport.EQUIVALENT_TIMEZONES)?_c('span',{staticClass:"jetlag-report"},[_c('CountryName',{attrs:{"country":_vm.report.destination}}),(_vm.report.destinationTimezonesOrdered.length === 1)?_c('span',[_vm._v(" is "),(_vm.report.dstDiscrepancyExists)?_c('span',[_vm._v(" currently ")]):_vm._e(),(_vm.report.destinationTimezonesOrdered[0].minutesOffset !== 0)?_c('span',[_c('v-tooltip',{attrs:{"bottom":"","light":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"tooltip-trigger"},on),[_c('Duration',{attrs:{"minutes":_vm.report.destinationTimezonesOrdered[0].minutesOffset},scopedSlots:_vm._u([{key:"negative",fn:function(ref){
var text = ref.text;
return [_vm._v(" "+_vm._s(text)+" behind you")]}},{key:"positive",fn:function(ref){
var text = ref.text;
return [_vm._v(" "+_vm._s(text)+" ahead of you")]}}],null,true)})],1)]}}],null,false,457533501)},[_c('span',{staticClass:"tooltip-text"},[_vm._v(" You'll turn your clocks "),_c('Duration',{attrs:{"minutes":_vm.report.destinationTimezonesOrdered[0].minutesClockTurn},scopedSlots:_vm._u([{key:"negative",fn:function(ref){
var text = ref.text;
return [_vm._v(" "+_vm._s(text)+" backward ")]}},{key:"positive",fn:function(ref){
var text = ref.text;
return [_vm._v(" "+_vm._s(text)+" forward ")]}}],null,false,2977306833)})],1)]),_vm._v(". ")],1):_c('span',[_vm._v(" in your time zone ")])]):_c('span',[_vm._v(" spans "+_vm._s(_vm.numberOfTimezones)+" different time zones, "),(_vm.report.dstDiscrepancyExists)?_c('span',[_vm._v(" currently ")]):_vm._e(),_vm._v(" from "),(_vm.report.destinationTimezonesOrdered[0].minutesOffset !== 0)?_c('v-tooltip',{attrs:{"bottom":"","light":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"tooltip-trigger"},on),[_c('Duration',{attrs:{"minutes":_vm.report.destinationTimezonesOrdered[0].minutesOffset},scopedSlots:_vm._u([{key:"negative",fn:function(ref){
var text = ref.text;
return [_vm._v(_vm._s(text)+" behind")]}},{key:"positive",fn:function(ref){
var text = ref.text;
return [_vm._v(_vm._s(text)+" ahead of")]}}],null,true)}),_vm._v(" yours ")],1)]}}],null,false,3511179707)},[_c('span',{staticClass:"tooltip-text"},[_vm._v(" You'll turn your clocks "),_c('Duration',{attrs:{"minutes":_vm.report.destinationTimezonesOrdered[0].minutesClockTurn},scopedSlots:_vm._u([{key:"negative",fn:function(ref){
var text = ref.text;
return [_vm._v(" "+_vm._s(text)+" backward ")]}},{key:"positive",fn:function(ref){
var text = ref.text;
return [_vm._v(" "+_vm._s(text)+" forward ")]}}],null,false,2977306833)})],1)]):_c('span',[_vm._v("your time zone")]),_vm._v(" to "),(_vm.report.destinationTimezonesOrdered[_vm.report.destinationTimezonesOrdered.length - 1].minutesOffset !== 0)?_c('span',[_c('v-tooltip',{attrs:{"bottom":"","light":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"tooltip-trigger"},on),[_c('Duration',{attrs:{"minutes":_vm.report.destinationTimezonesOrdered[_vm.report.destinationTimezonesOrdered.length - 1].minutesOffset},scopedSlots:_vm._u([{key:"negative",fn:function(ref){
var text = ref.text;
return [_vm._v(_vm._s(text)+" behind")]}},{key:"positive",fn:function(ref){
var text = ref.text;
return [_vm._v(_vm._s(text)+" ahead of")]}}],null,true)}),_vm._v(" yours")],1)]}}],null,false,1228689902)},[_c('span',{staticClass:"tooltip-text"},[_vm._v(" You'll turn your clocks "),_c('Duration',{attrs:{"minutes":_vm.report.destinationTimezonesOrdered[_vm.report.destinationTimezonesOrdered.length - 1].minutesClockTurn},scopedSlots:_vm._u([{key:"negative",fn:function(ref){
var text = ref.text;
return [_vm._v(" "+_vm._s(text)+" backward ")]}},{key:"positive",fn:function(ref){
var text = ref.text;
return [_vm._v(" "+_vm._s(text)+" forward ")]}}],null,false,2977306833)})],1)]),_vm._v(". ")],1):_c('span',[_vm._v("your time zone.")])],1),_c('JetlagInfo',{attrs:{"bedtimeShift":_vm.report.preparatoryBedtimeShift,"lightInfo":_vm.report.jetlagLightTips,"jetlagEffectOfDirection":_vm.report.jetlagEffectOfDirection}})],1):_vm._e(),(!_vm.report.deviceIsInHomeTimezone)?_c('div',{staticClass:"card-warning"},[_vm._v(" Note: this advice pertains to "),_c('a',{on:{"click":function($event){return _vm.changeHomeTimezone()}}},[_vm._v("your Trawel home time zone")]),_vm._v(", which seems to be different than your device's! ")]):_vm._e()]):_vm._e(),_c('v-card-actions',{staticClass:"card-actions"},[(_vm.moreInfoAvailable)?_c('Disclaimer',{staticClass:"more-timezone-info",attrs:{"title":"Time Difference - Further Info","button":"More Details"},scopedSlots:_vm._u([{key:"common",fn:function(){return [(!_vm.report.deviceIsInHomeTimezone)?_c('div',[_c('i',[_vm._v(" Note: this advice pertains to your Trawel home time zone, which seems to be different than your device's. ")])]):_vm._e()]},proxy:true}],null,false,3772496377)},[_c('v-divider'),(_vm.report)?_c('div',_vm._l((_vm.report.destinationTimezonesOrdered),function(tz){return _c('div',{key:tz.name,staticClass:"disclaimer-timezone"},[_c('b',[_vm._v(_vm._s(tz.name))]),_c('ul',[_c('li',[(_vm.report.dstDiscrepancyExists)?_c('span',[_vm._v(" Currently ")]):_c('span',[_vm._v(" Time zone is ")]),(tz.minutesOffset !== 0)?_c('Duration',{attrs:{"minutes":tz.minutesOffset},scopedSlots:_vm._u([{key:"negative",fn:function(ref){
var text = ref.text;
return [_vm._v(_vm._s(text)+" behind you.")]}},{key:"positive",fn:function(ref){
var text = ref.text;
return [_vm._v(_vm._s(text)+" ahead of you.")]}}],null,true)}):_c('span',[_vm._v("in your time zone.")])],1),(tz.jetlag)?_c('li',[_c('JetlagInfo',{attrs:{"bedtimeShift":tz.jetlag.preparatoryBedtimeShift,"jetlagEffectOfDirection":tz.jetlag.effectOfDirection}})],1):_vm._e(),(tz.jetlag && tz.jetlag.light && tz.jetlag.light.seek)?_c('li',[_vm._v(" Once arrived, seek light from "+_vm._s(_vm.parseTime(tz.jetlag.light.seek.fromMinute))+" to "+_vm._s(_vm.parseTime(tz.jetlag.light.seek.toMinute))+". ")]):_vm._e(),(tz.jetlag && tz.jetlag.light && tz.jetlag.light.avoid)?_c('li',[_vm._v(" Once arrived, avoid light from "+_vm._s(_vm.parseTime(tz.jetlag.light.avoid.fromMinute))+" to "+_vm._s(_vm.parseTime(tz.jetlag.light.avoid.toMinute))+". ")]):_vm._e()])])}),0):_vm._e()],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }