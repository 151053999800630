










































import mixins from 'vue-typed-mixins';
import { CardMixin } from '@/mixins/CardMixin';
import { CardType } from '@/types/CardType';
import { Tipping, TippingCustom } from '@/modules/tips/Tipping';
import { NameOfCountry } from '@/modules/NameOfCountry';
import { CardPriority } from '@/types/CardPriority';
import { CardImage } from '@/types/CardImage';
import Disclaimer from '@/components/Disclaimer.vue';
import { TippingCulture } from '@/types/tips/TippingCulture';

interface Data {
  report?: TippingCustom;
}

export default mixins(CardMixin).extend({
  name: 'TipsCard',

  components: {
    Disclaimer,
  },

  watch: {
    pending: {
      handler() {
        this.loadReport();
      },
    },
  },

  data(): Data {
    return {
      report: undefined,
    };
  },

  computed: {

    cardType() {
      return CardType.TIPS;
    },

    subtitle(): string {
      if (this.loading) {
        return '';
      }

      const summary = this.report?.summary;

      if (!summary) {
        return 'No info available';
      }

      return `Tipping is ${summary}`;
    },

    details() {
      if (!this.report) {
        return [];
      }

      const { details } = this.report as TippingCustom;

      return Object.entries(details).map(([key, value]) => ({
        recipient: key,
        instruction: value,
      }));
    },

    cardImages(): CardImage[] {
      switch (this.report?.tipping) {
      case TippingCulture.CUSTOMARY:
        return [{
          description: this.subtitle,
          icon: 'mdi-hand-coin-outline',
        }];
      case TippingCulture.APPRECIATED:
        return [{
          description: this.subtitle,
          icon: 'mdi-hand-heart-outline',
        }];
      default:
        return [{
          description: this.subtitle,
          icon: 'mdi-hand-extended-outline',
        }];
      }
    },

    cardBackground(): CardPriority {
      if (this.loading || this.error || !this.report || !this.origin) {
        return CardPriority.OK;
      }

      if (this.origin === this.destination) {
        return CardPriority.OK;
      }

      const homeCulture = this.report?.origin?.tipping;
      const destinationCulture = this.report.tipping;

      switch (homeCulture) {
      case TippingCulture.APPRECIATED:
        return destinationCulture === TippingCulture.CUSTOMARY ? CardPriority.ISSUE : CardPriority.WARNING;

      case TippingCulture.CUSTOMARY:
        return destinationCulture === TippingCulture.CUSTOMARY ? CardPriority.OK : CardPriority.WARNING;

      default:
        if (!this.origin) {
          return CardPriority.OK;
        }
        if (destinationCulture === TippingCulture.APPRECIATED) {
          return CardPriority.WARNING;
        }
        if (destinationCulture === TippingCulture.CUSTOMARY) {
          return CardPriority.ISSUE;
        }

        return CardPriority.OK;
      }
    },
  },

  methods: {

    async loadReport(): Promise<void> {
      this.isLoadingData = true;
      this.error = undefined;
      this.report = undefined;

      try {
        const reporter = new Tipping(this.destination);
        this.report = await reporter.get(this.origin);
      } catch (e) {
        this.error = this.error || `No tipping data found for ${NameOfCountry.get(this.destination) || this.destination.toUpperCase()}`;
      }
      this.isLoadingData = false;
    },
  },
});
