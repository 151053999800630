import { CountryCode } from '@/types/CountryCode';
import { ExchangeRates } from '@/types/cash/ExchangeRates';
import { CurrencyCode } from '@/types/cash/CurrencyCode';
import { APIClientTools } from '@/modules/APIClientTools';
import axios from 'axios';
import DateTools from '@/modules/DateTools';

export enum DataValidity {
  VALID,
  UNRELIABLE,
  ABSENT
}

interface CachedExchangeRates {
  cache?: ExchangeRates;
  obtained: Date;
}

export type ExchangeRate = {
  validity: DataValidity.ABSENT;
} | {
  rate: number;
  validity: DataValidity.VALID;
} | {
  rate: number;
  referenced: Date;
  validity: DataValidity.UNRELIABLE;
}

export class ExchangeRatesManager {
  public static STORAGE_KEY = 'cash/exchange-rates';

  public static CACHE_REFRESH_AFTER_MS = +(process.env.VUE_APP_CACHE_REFRESH_MS as string);

  public static CACHE_INVALID_AFTER_MS = +(process.env.VUE_APP_CACHE_EXPIRY_MS as string)

  public static purge() {
    localStorage.removeItem(this.STORAGE_KEY);
  }

  private static getFromStorage(): CachedExchangeRates {
    const result = localStorage.getItem(ExchangeRatesManager.STORAGE_KEY);
    if (!result) {
      return {
        cache: undefined,
        obtained: new Date(-1),
      };
    }

    try {
      const json: unknown = JSON.parse(result);
      const parsedDate = new Date((json as any).obtained);
      if (Number.isNaN(parsedDate.getTime())) {
        throw new Error('Invalid date');
      }
      if (DateTools.isInFuture(parsedDate)) {
        throw new Error('Date set in future');
      }
      (json as any).obtained = parsedDate;
      return json as CachedExchangeRates;
    } catch (e) {
      console.warn('Malformed cache:', e, result);
      return {
        cache: undefined,
        obtained: new Date(-1),
      };
    }
  }

  private static refreshCache(): Promise<CachedExchangeRates> {
    return APIClientTools.withRetries(() => axios
      .get('https://api.exchangerate.host/latest?base=EUR')
      .then((apiResult) => {
        const { data } = apiResult;
        const { rates } = data as {rates: ExchangeRates};

        // These Errors will trigger API refresh
        if (!rates) {
          throw new Error('Exchange API call returned without rates');
        }

        const result: CachedExchangeRates = {
          cache: rates,
          obtained: new Date(Date.now()),
        };

        console.info('Caching exchange rates for', this.CACHE_REFRESH_AFTER_MS / (1000 * 60), 'minutes.');

        this.set(result);

        return result;
      }));
  }

  private static assertConfigOk() {
    const {
      CACHE_INVALID_AFTER_MS,
      CACHE_REFRESH_AFTER_MS,
    } = this;

    if (!CACHE_INVALID_AFTER_MS || !CACHE_REFRESH_AFTER_MS) {
      console.warn({
        CACHE_INVALID_AFTER_MS,
        CACHE_REFRESH_AFTER_MS,
      });
      throw new Error('Missing cache env vars');
    }
  }

  public static async get(currency: CurrencyCode): Promise<ExchangeRate> {
    this.assertConfigOk();

    let { cache, obtained } = this.getFromStorage();
    const isOutOfDate: () => boolean = () => !!obtained && +obtained + this.CACHE_REFRESH_AFTER_MS < Date.now();

    if (+obtained + this.CACHE_INVALID_AFTER_MS < Date.now()) {
      console.info('Cached exchange rates expired or missing, refreshing.');
      cache = undefined;
    }

    if (!cache || isOutOfDate()) {
      ({ cache, obtained } = await this.refreshCache()
        .catch((e) => {
          console.warn('Refreshing exchange rates cache failed', e);
          return { cache, obtained }; // Fall back on old value
        }));
    }

    let result: ExchangeRate;
    if (!cache || !(currency in cache)) {
      result = {
        validity: DataValidity.ABSENT,
      };
    } else if (isOutOfDate()) { // still out of date
      result = {
        rate: cache[currency] as number,
        validity: DataValidity.UNRELIABLE,
        referenced: obtained,
      };
    } else {
      result = {
        rate: cache[currency] as number,
        validity: DataValidity.VALID,
      };
    }

    return result;
  }

  private static set(rates: CachedExchangeRates): void {
    return localStorage.setItem(this.STORAGE_KEY, JSON.stringify(rates));
  }
}
