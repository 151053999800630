
























































































































import Vue, { PropType } from 'vue';
import {
  Currency, CurrencyCompatibilityReport,
} from '@/modules/cash/Currency';
import { CountryCode, CountryCodes } from '@/types/CountryCode';
import CountryCard from '@/components/CountryCard.vue';
import { CardImage } from '@/types/CardImage';
import { CardPriority } from '@/types/CardPriority';
import { Compatibility } from '@/types/Compatibility';
import { NameOfCountry } from '@/modules/NameOfCountry';
import CurrencyReport from '@/components/cash/CurrencyReport.vue';
import CountryName from '@/components/CountryName.vue';
import DenominationList from '@/components/cash/DenominationList.vue';
import { CardType } from '@/types/CardType';
import { CardMixin } from '@/mixins/CardMixin';
import mixins from 'vue-typed-mixins';
import { Denomination } from '@/types/cash/Denomination';

interface Data {
  report?: CurrencyCompatibilityReport;
  denominations?: Denomination[];
}

export default mixins(CardMixin).extend({
  name: 'CashCard',

  components: {
    CurrencyReport,
    DenominationList,
  },

  data(): Data {
    return {
      report: undefined,
      denominations: undefined,
    };
  },

  computed: {

    cardType() {
      return CardType.CASH;
    },

    subtitle(): string {
      if (this.loading) {
        return '';
      }

      switch (this.report?.originCompatibilityWithDestination) {
      case Compatibility.NONE:
        return 'For cash transactions, you\'ll need to exchange money';

      case Compatibility.SOME_ARE_COMPATIBLE:
      case Compatibility.FULL:
        return 'You don\'t need to exchange any cash';

      case Compatibility.COMPATIBLE_WITH_SOME:
      case Compatibility.LIMITED_OVERLAP:
        return 'You probably won\'t need to exchange any cash';

      default:
        return 'Couldn\'t compare currencies';
      }
    },

    cardImages(): CardImage[] {
      const denominations: Denomination[] | undefined = this.report ? this.report.destinationDenominations : this.denominations;

      if (!denominations) {
        return [];
      }

      return denominations.map(({ symbol, colloquial }: Denomination, i, arr) => {
        const additionalInfo = arr.length > 1 ? ` (${i + 1}/${arr.length})` : '';

        return {
          codes: symbol.length > 0 ? symbol : ['2469'],
          description: `${NameOfCountry.get(this.destination)} uses the ${colloquial}${additionalInfo}`,
        };
      });
    },

    cardBackground(): CardPriority {
      if (!this.origin || !this.report || this.loading || this.error) {
        return CardPriority.OK;
      }

      switch (this.report.originCompatibilityWithDestination) {
      case Compatibility.NONE:
        // As long as there's one common currency, we're good
        return CardPriority.ISSUE;

      default:
        return CardPriority.OK;
      }
    },
  },

  methods: {

    changeHomeCountry() {
      this.$store.commit('toggleHomeCountryDialog', true);
    },

    async loadReport(): Promise<void> {
      this.isLoadingData = true;
      this.error = undefined;
      this.denominations = undefined;
      this.report = undefined;

      try {
        const destinationCurrency = new Currency(this.destination);

        try {
          await new Promise<void>((resolve, reject) => {
            if (!this.origin) {
              reject(new Error('Missing origin'));
            } else {
              resolve();
            }
          })
            .then(async () => {
              this.report = await destinationCurrency.report(this.origin);
            })
            .catch(async () => {
              this.denominations = await destinationCurrency.get();
            });
        } catch (e) {
          const country = NameOfCountry.get(this.destination) || this.destination.toUpperCase();
          this.error = `No currency data found for ${country}`;
          throw e;
        }
      } catch (e) {
        this.report = undefined;
      }

      this.isLoadingData = false;
    },
  },
});
