















































import Vue, { PropType } from 'vue';
import {
  Socket, SocketCompatibilityReport,
} from '@/modules/elec/Socket';
import SocketCompatibilityReportComponent from '@/components/elec/SocketCompatibilityReport.vue';
import { CountryCode, CountryCodes } from '@/types/CountryCode';
import CountryCard from '@/components/CountryCard.vue';
import SocketName from '@/components/elec/SocketName.vue';
import { CardImage } from '@/types/CardImage';
import { CardPriority } from '@/types/CardPriority';
import { Compatibility } from '@/types/Compatibility';
import { NameOfCountry } from '@/modules/NameOfCountry';
import CountryName from '@/components/CountryName.vue';
import { CardType } from '@/types/CardType';
import { CardMixin } from '@/mixins/CardMixin';
import mixins from 'vue-typed-mixins';
import { SocketType } from '@/types/elec/SocketType';
import { SocketInfo } from '@/types/elec/SocketInfo';

interface Data {
  compatiblityReport?: SocketCompatibilityReport;
  socket?: SocketInfo;
}

export default mixins(CardMixin).extend({
  name: 'ElecCard',

  components: {
    SocketCompatibilityReportComponent,
    SocketName,
  },

  data(): Data {
    return {
      compatiblityReport: undefined,
      socket: undefined,
    };
  },

  computed: {
    cardType() {
      return CardType.ELEC;
    },

    subtitle(): string {
      if (this.loading) {
        return '';
      }

      if (!this.compatiblityReport) {
        return 'No info available for these two countries';
      }

      const voltageOk = this.compatiblityReport.voltageCompatibility === Compatibility.FULL;

      switch (this.compatiblityReport.plugCompatibilityWithSocket) {
      case Compatibility.FULL:
        return `Your devices are compatible${voltageOk ? '!' : ', but confirm the voltage'}`;

      case Compatibility.NONE:
        return 'Your devices are incompatible';

      case Compatibility.COMPATIBLE_WITH_SOME:
        return 'Your devices will only fit into certain sockets';

      case Compatibility.SOME_ARE_COMPATIBLE:
        return 'Some of your devices are compatible';

      case Compatibility.LIMITED_OVERLAP:
        return 'Some of your devices are compatible, but only with certain sockets';

      default:
        console.error(`Unknown compatibility '${this.compatiblityReport.plugCompatibilityWithSocket}'`);
        return '';
      }
    },

    cardImages(): CardImage[] {
      if (!this.socket) {
        return [];
      }

      const country = NameOfCountry.get(this.destination);

      return this.socket.plugs.map(({ name, colloquial }, i, arr) => ({
        url: `img/elec/${name}.svg`,
        description: `${country} uses the ${colloquial}, Type ${name} socket${arr.length > 1 ? ` (${i + 1}/${arr.length})` : ''}`,
      }));
    },

    cardBackground(): CardPriority {
      if (!this.origin || !this.compatiblityReport || this.loading || this.error) {
        return CardPriority.OK;
      }

      switch (this.compatiblityReport.plugCompatibilityWithSocket) {
      case Compatibility.FULL:

        return this.compatiblityReport.voltageCompatibility === Compatibility.FULL ? CardPriority.OK : CardPriority.WARNING;

      case Compatibility.NONE:
        return CardPriority.ISSUE;

      default:
        return CardPriority.WARNING;
      }
    },
  },

  methods: {
    changeHomeCountry() {
      this.$store.commit('toggleHomeCountryDialog', true);
    },
    async loadReport(): Promise<void> {
      this.isLoadingData = true;
      this.error = undefined;

      try {
        const destinationSocket = new Socket(this.destination);

        try {
          this.socket = await destinationSocket.info;
        } catch (e) {
          const country = NameOfCountry.get(this.destination) || this.destination.toUpperCase();
          this.error = `No electrical sockets found for ${country}`;
          throw e;
        }

        if (!this.origin) {
          this.error = 'Select your home country to see compatiblity';
          throw new Error('Origin not selected');
        }

        const originSocket = new Socket(this.origin);

        try {
          await originSocket.info;
        } catch (e) {
          this.error = `No electrical plugs found for ${NameOfCountry.get(this.origin) || this.origin.toUpperCase()}`;
          throw e;
        }

        // Sanity check for both countries passed. Load compatibility

        try {
          const report = await new Socket(this.origin).compatibleWith(destinationSocket);
          this.compatiblityReport = report;
        } catch (e) {
          this.error = 'Could not infer compatibility for these countries';
          console.error(e); // Unexpected error
          throw e;
        }
      } catch (e) {
        this.compatiblityReport = undefined;
      }

      this.isLoadingData = false;
    },
  },
});
