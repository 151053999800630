














































































































































































































import Vue, { PropType } from 'vue';
import { CountryCode, CountryCodes } from '@/types/CountryCode';
import { CardImage } from '@/types/CardImage';
import { CardColor, CardPriority } from '@/types/CardPriority';
import CountryName from '@/components/CountryName.vue';

interface Data {
  displayedImage: number;
  carouselCyclingEnabled: boolean;
  hoveringCarousel: boolean;

  windowWidth: number;
}

export default Vue.extend({
  name: 'CountryCard',

  data(): Data {
    return {
      windowWidth: window.innerWidth,

      // Carousel control
      displayedImage: 0,
      carouselCyclingEnabled: true,
      hoveringCarousel: false,
    };
  },

  props: {
    country: {
      type: String as PropType<CountryCode>,
      required: true,
    },
    priority: {
      type: Number as PropType<CardPriority>,
      required: false,
    },
    images: {
      type: Array as PropType<CardImage[]>,
      required: true,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  computed: {
    origin(): CountryCode {
      return this.$store.state.home;
    },
    cardBackground(): string {
      return CardColor[
        this.priority ? this.priority : CardPriority.OK
      ];
    },
    doCarouselCycle(): boolean {
      return this.carouselCyclingEnabled && !this.hoveringCarousel;
    },
    isMobileWidth(): boolean {
      return !(this.windowWidth > 768);
    },
  },

  methods: {

    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  components: {
    CountryName,
  },
});
