export const LegibleDuration = (minutes: number): string => {
  if (!minutes) {
    throw new Error("Can't display zero-duration");
  }

  const turn = Math.abs(minutes);
  let hours: number;

  if (turn % 60 === 0) {
    hours = turn / 60;
  } else if (turn < 60) {
    hours = 0;
  } else {
    hours = Math.floor(turn / 60);
  }

  const mins = turn - (hours * 60);

  if (hours && mins && mins === 30) {
    return `${hours}½ hours`;
  }
  if (hours && mins) {
    return `${hours} hour${hours === 1 ? '' : 's'} and ${mins} minute${mins === 1 ? '' : 's'}`;
  }
  if (hours) {
    return `${hours} hour${hours === 1 ? '' : 's'}`;
  }
  return `${mins} minute${mins === 1 ? '' : 's'}`;
};
