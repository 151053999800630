








































import Vue, { PropType } from 'vue';
import { Socket } from '@/modules/elec/Socket';
import { CountryCode, CountryCodes } from '@/types/CountryCode';
import { SocketType } from '@/types/elec/SocketType';

export default Vue.extend({
  name: 'CountryName',

  props: {
    // Plugs
    in: {
      type: Array as PropType<SocketType[]>,
      required: true,
    },

    // Sockets
    out: {
      type: Array as PropType<SocketType[]>,
      required: true,
    },
  },

  computed: {
    needMultiAdapter(): boolean {
      if (this.outputColloquial.length <= 1) {
        return false;
      }

      return true;
    },

    adapterPrefix(): 'an' | 'a' {
      const firstInput = this.inputColloquial[0];
      const needPrefixAn = ['Indian-type', 'Italian-type'].includes(firstInput);

      return needPrefixAn ? 'an' : 'a';
    },

    /**
     * explanation of why we do the following input/output filterings:
     *
     * If one of the sockets/plugs will unoquivocally work
     * with all the other country's plugs/sockets, it will
     * logically never need an adapter - so we remove it from here.
     *
     * However, if there is even one plug/socket that won't work,
     * we obviously need an adapter, even if in some/most cases no
     * adapter is needed.
     *
     * The one exception is with grounding pairs. Consider this scenario
     * under the above logic:
     * - User is in a country with a grounding pair (eg. C/F)
     * - User goes to another country with that grounding pair
     *   (matching algo passes, this component is not created)
     * - User goes to another country with that grounding pair AND one extra (eg C/F/L)
     *   (matching algo fails, this component is created)
     * - Per the logic above, F will not fit into C, so we would naively recommend a multi-adapter
     * - However, since that same grounding pair exists in the home country, we don't need to cater
     *   to it! The only new thing is the "L" socket
     *
     * However, we can't just blindy strip the types that have a grounding pair
     * in the other country, since some countries ONLY have one or the other.
     * So our solution is to check if the exact same grounding duality exists in both
     * countries - and only strip them away if this applies. The thinking here is that the user is
     * already used to dealing with their home countr's grounding duality - it doesn't
     * warrant additional requirements from the adapter.
     */

    input(): SocketType[] {
      return this.in.filter((plug: SocketType) => !this.out.every((socket) => {
        const exactSameGroundingDualityExists = Socket.areGroundingPairs(plug, socket)
          && typeof this.out.find(({ name }) => name === plug.name) !== 'undefined'
          && typeof this.in.find(({ name }) => name === socket.name) !== 'undefined';

        return Socket.willFitInto(plug.name, socket.name) || exactSameGroundingDualityExists;
      }));
    },

    output(): SocketType[] {
      return this.out.filter((socket: SocketType) => !this.in.every((plug) => {
        const exactSameGroundingDualityExists = Socket.areGroundingPairs(plug, socket)
          && typeof this.out.find(({ name }) => name === plug.name) !== 'undefined'
          && typeof this.in.find(({ name }) => name === socket.name) !== 'undefined';

        return Socket.willFitInto(plug.name, socket.name) || exactSameGroundingDualityExists;
      }));
    },

    inputColloquial(): string[] {
      return this.stripGroundingPairs(this.input)
        .map(({ colloquial }) => colloquial);
    },

    outputColloquial(): string[] {
      return this.stripGroundingPairs(this.output)
        .map(({ colloquial }) => colloquial);
    },
  },

  mounted() {
    this.$nextTick(() => {
      // Sanity check to be caught by Analytics
      if (this.output.length < 1 || this.input.length < 1) {
        console.error('Adapter error!', this.out, this.in);
      }
    });
  },

  methods: {

    areEqualOneItemArrays(a: unknown[], b: unknown[]): boolean {
      if (a.length !== b.length) {
        return false;
      }

      if (a.length !== 1) {
        return false;
      }

      return a[0] === b[0];
    },

    stripGroundingPairs(list: SocketType[]): SocketType[] {
      const stripped: SocketType[] = [];

      list.forEach((socket: SocketType) => {
        const groundingPair = stripped.find((elem) => Socket.areGroundingPairs(elem, socket));
        if (typeof groundingPair === 'undefined') {
          stripped.push(socket);
        }
      });

      return stripped;
    },
  },
});
