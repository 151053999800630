import { CountryCode } from '@/types/CountryCode';

import data from '@/data/tips/tips.json';
import { DataRecord } from '@/types/DataRecord';
import { TippingCulture } from '@/types/tips/TippingCulture';

interface RawTippingCustom extends DataRecord {
  'tipping': TippingCulture;
  'summary': string;
  'details': Record<string, string>;
  'explanation': string;
}

export interface TippingCustom extends RawTippingCustom {
  origin?: RawTippingCustom;
}

const tips = data as {
  [code in CountryCode]?: RawTippingCustom;
};

class FaultyTippingError extends Error {
  constructor(message: string, public readonly country: CountryCode) {
    super(`${message} for country '${country}'`);
    this.name = 'FaultyTippingError';
  }
}

export class Tipping {
  private readonly info: Promise<RawTippingCustom>;

  constructor(private readonly destination: CountryCode) {
    this.info = Tipping.getForCountry(destination);
  }

  private static getForCountry(country: CountryCode): Promise<RawTippingCustom> {
    return new Promise((resolve, reject) => {
      const result = tips[country];

      if (!result) {
        throw new FaultyTippingError('No tipping info listed', country);
      }
      resolve(result);
    });
  }

  async get(homeCountry?: CountryCode): Promise<TippingCustom> {
    const original = await this.info;

    const origin = homeCountry ? await Tipping.getForCountry(homeCountry).catch(() => undefined) : undefined;

    return {
      ...original,
      origin,
    };
  }
}
