



































































































import Vue, { PropType } from 'vue';
import {
  Socket, SocketCompatibilityReport,
} from '@/modules/elec/Socket';
import { CountryCode, CountryCodes } from '@/types/CountryCode';
import SocketName from '@/components/elec/SocketName.vue';
import SocketAdapter from '@/components/elec/SocketAdapter.vue';
import { Compatibility } from '@/types/Compatibility';
import { SocketType } from '@/types/elec/SocketType';

export default Vue.extend({
  name: 'SocketCompatibilityReport',

  props: {
    report: {
      type: Object as PropType<SocketCompatibilityReport>,
      required: false,
    },
  },

  components: {
    SocketName,
    SocketAdapter,
  },

  data() {
    return {
      Compatibility,
    };
  },
});
